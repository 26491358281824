/* eslint-disable import/prefer-default-export */
import i18n from '@/libs/i18n';

export const myselfMenuItems = [
  'myDashboard',
  'globalMemberSettings',
  'localMemberSettings',
  'contactInfo',
  'myRoles',
  'myConnections',
  'myself-subcommunities',
  'calendar',
  'email',
  // 'chats',
  'organization-referrals',
  'myself-referrals',
  'orders',
  'my-payments',
  'passport',
  'myself-notifications',
  'my-role-details',
  'myself-likes',
];

export function memberSettingsItems(communityCustomizations) {
  const myselfMenuActive = [];

  const myselfMenu = [
    {
      title: i18n.t("myself.menu.dashboard"),
      route: "myDashboard",
      icon: "MonitorIcon",
      action: "view",
      resource: "dashboard",
      code: "dashboard",
    },
    {
      title: i18n.t("myself.menu.global-settings"),
      route: "globalMemberSettings",
      icon: "SettingsIcon",
      action: "view",
      resource: "settings",
      code: "settings",
    },
    {
      title: i18n.t("myself.menu.local-settings"),
      route: "localMemberSettings",
      icon: "SettingsIcon",
      action: "view",
      resource: "settings",
      code: "settings",
    },
    {
      title: i18n.t("myself.menu.contact-info"),
      route: "contactInfo",
      icon: "SettingsIcon",
      action: "view",
      resource: "settings",
      code: "settings",
    },
    {
      title: i18n.t("myself.menu.badge"),
      route: "passport",
      icon: "KeyIcon",
      action: "view",
      resource: "passport",
      code: "passport",
    },
    {
      title: i18n.t("myself.menu.notifications"),
      route: "myself-notifications",
      icon: "BellIcon",
      action: "view",
      resource: "notifications",
      code: "notifications",
    },
    {
      title: i18n.t("myself.menu.roles"),
      route: "myRoles",
      icon: "ShieldIcon",
      action: "view",
      resource: "roles",
      code: "roles",
    },
    {
      title: i18n.t("myself.menu.spaces"),
      route: "myself-subcommunities",
      icon: "TrelloIcon",
      action: "view",
      resource: "spaces",
      code: "spaces",
    },
    {
      title: i18n.t("myself.menu.calendar"),
      route: "calendar",
      icon: "CalendarIcon",
      action: "view",
      resource: "calendar",
      code: "calendar",
    },
    {
      title: i18n.t("myself.menu.messages"),
      route: "email",
      icon: "MailIcon",
      action: "view",
      resource: "messages",
      code: "messages",
    },
    // TODO: volver a poner el chat cuando funcione correctamente
    // {
    //   title: i18n.t("myself.menu.chat"),
    //   route: "chats",
    //   icon: "MessageSquareIcon",
    //   action: "view",
    //   resource: "chat",
    //   code: "chat",
    // },
    {
      title: i18n.t("myself.menu.transactions"),
      route: "my-payments",
      icon: "DollarSignIcon",
      action: "view",
      resource: "payments",
      code: "payments",
    },
    {
      title: i18n.t("myself.menu.orders"),
      route: "orders",
      icon: "ShoppingBagIcon",
      action: "view",
      resource: "orders",
      code: "orders",
    },
    {
      title: i18n.t("myself.menu.connections"),
      route: "myConnections",
      icon: "UsersIcon",
      action: "view",
      resource: "connections",
      code: "connections",
    },
    {
      title: i18n.t("myself.menu.referrals"),
      route: "myself-referrals",
      icon: "Share2Icon",
      action: "view",
      resource: "referrals",
      code: "referrals",
    },
    {
      title: i18n.t("myself.menu.likes"),
      route: "myself-likes",
      icon: "ThumbsUpIcon",
      action: "view",
      resource: "likes",
      code: "likes",
    },
  ];

  if (communityCustomizations && Object.values(communityCustomizations).length > 0) {
    myselfMenu.forEach((menuItem) => {
      if (communityCustomizations[menuItem.code]) {
        myselfMenuActive.push(menuItem);
      }
      // communityCustomizations[menuItem.code] ? myselfMenuActive.push(menuItem) : '';
    });
    return myselfMenuActive;
  }
  return myselfMenu;
}
